import React, { FC, useEffect } from "react";

import "./style.scss";

// enum LinkTargetType {
//     Unknown = 0,
//     Screen,
//     Dialog,
// }

interface LinkTarget {
    target: string;
    type: any;
}

export interface LinkProps {
    text: string;
    target: string | LinkTarget[];
    className?: string;
    users?: [];
    failure?: string | LinkTarget[];
    visibleTo?: string[];

    onClick?: (target: string | LinkTarget[], shiftKey: boolean, users: string[], failure: string | LinkTarget[]) => void;
    onRendered?: () => void;
}

const Link: FC<LinkProps> = (props) => {
    // console.log(props);
    const { text, target, className, users, failure, onClick, onRendered } = props;
    const css = ["__link__", className ? className : null].join(" ").trim();
    // console.log(props);

    let touches = 0;
    const handleTouchStart = (e: React.TouchEvent<HTMLSpanElement>) => {
        touches = e.touches.length;
    };
    const handleTouchEnd = (e: React.TouchEvent<HTMLSpanElement>) => {
        e.preventDefault(); // prevents the click event firing
        console.log("handleTouchEnd");
        onClick && onClick(target, touches > 1, users, failure);
        touches = 0;
    };

    const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        // console.log("click");
        onClick && onClick(target, e.shiftKey, users, failure);
    };
    const handleRendered = () => (onRendered && onRendered());

    // this should fire on mount/update
    useEffect(() => handleRendered());

    return (
        <span
            className={css}
            onClick={handleClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            {text}
        </span>
    );
};

export default Link;
